import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { environmentLoader } from './environments/environment.loader';
import { mergeDeep } from 'src/app/shared/misc/object.utils';

environmentLoader.then(update => {
  mergeDeep(environment, update);

  if (environment.production) {
    enableProdMode();
  }

  // dynamically load the app module
  // -> otherwise the loaded environment settings will not be applied
  import('./app/app.module').then(m => {
    platformBrowserDynamic()
      .bootstrapModule(m.AppModule)
      .catch(err => console.error(err));
  });
});
